<template>
  <div>
    <!-- <Base /> -->
    <router-view></router-view>
    <v-snackbar :value="updateExists" :timeout="-1">
      An update is available
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="refreshApp">
          Update
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import update from './mixins/update.js';
// import Base from '@/views/Base';

export default {
  name: 'App',

  // components: { Base },

  data: () => ({
    //
  }),

  mixins: [update]
};
</script>
