import Vue from 'vue';
import VueRouter from 'vue-router';
//import Login3 from '@/views/Login3';
import Home from '@/components/Home';
//import store from '../store/index';
import { HTTP } from '../http-common';

Vue.use(VueRouter);

const routes = [
  //  {
  //    path: '/login',
  //    name: 'Login',
  //    component: Login3
  //  },
  {
    path: '/',
    name: 'Home',
    component: Home
    //    meta: {
    //      requiredAuth: true
    //    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let routeFound = null;

  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const path = route.path;

    if (path.includes(':')) {
      // dynamic path
      let segments = path.split('/');
      for (let j = 0; j < segments.length; j++) {
        if (segments[j].indexOf(0) == ':') segments[j] = '.*/';
      }
      if (path == segments.join('/')) {
        routeFound = route;
      }
    } else {
      if (path == to.path) {
        routeFound = route;
      }
    }

    if (routeFound) break;
  }

  if (localStorage.getItem('device-id')) {
    HTTP.defaults.headers.common['device-id'] = localStorage.getItem(
      'device-id'
    );
  }

  const requiredAuth = to.matched.some(record => record.meta.requiredAuth);
  if (requiredAuth && localStorage.getItem('device-id') == null) {
    next({
      name: 'Login'
      //query: {
      //  from: to.fullPath
      //}
    });
  }

  next();
});

export default router;
