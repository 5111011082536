import Vue from 'vue';
import Vuex from 'vuex';
// import jsonwebtoken from 'jsonwebtoken';
import { HTTP } from '../http-common';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    user: {}
  },
  mutations: {
    login: (state, user) => {
      state.user = user;
    },

    logout: state => {
      state.user = {};
    },

    homeView: () => {
      router.push({ name: 'Home' });
    },

    updateUser: (state, payload) => {
      state.user = payload;
    }
  },
  actions: {
    login: async (context, deviceId) => {
      localStorage.setItem('device-id', deviceId);

      // set jwt for default http client (axios)
      HTTP.defaults.headers.common['device-id'] = deviceId;
    },

    logout: context => {
      localStorage.removeItem('jwt');
      context.commit('logout');
    },

    homeView: context => {
      context.commit('homeView');
    }
  },
  modules: {},
  getters: {
    user: state => {
      return state.user;
    },

    moduleWhitelist: state => {
      return state.user.moduleWhitelist;
    }
  }
});
