<template>
  <v-app>
    <v-app-bar :color="colorBlue" dark dense app>
      <v-img
        class="mr-3"
        src="../assets/logo.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-toolbar-title class="product-title">
        Product Tracker
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <Loading :loading="loadingCount > 0" />
        <br /><br />
        <v-row>
          <v-col align="center">
            <template v-if="!showScanner">
              <v-btn
                :color="colorBlue"
                v-if="!showScanner"
                @click="
                  showScanner = true;
                  showData = false;
                "
                >Scan</v-btn
              >
              <br />
              <br />
              <div class="text-left">
                <strong>Instructions</strong>
                <ol>
                  <li>Tap "SCAN" button.</li>
                </ol>
                <br />
                <strong>උපදෙස්</strong>
                <ol>
                  <li>"SCAN" බොත්තම ඔබන්න.</li>
                </ol>
              </div>
            </template>
            <template v-if="showScanner">
              <div style="width:300px" v-if="showScanner">
                <v-zxing @decode="onDecode" @loaded="onLoaded" />
                <br />
                <v-btn
                  :color="secondary"
                  v-if="showScanner"
                  @click="showScanner = false"
                  >Back</v-btn
                >
              </div>
              <div class="text-left">
                <strong>Instructions</strong>
                <ol>
                  <li>
                    If there is a notification asking for camera permisison,
                    grant permission.
                  </li>
                  <li>
                    If the camera is not rear camera, select from video source
                    drop down.
                  </li>
                  <li>Point the camera to the data matrix code.</li>
                </ol>
                <br />
                <strong>උපදෙස්</strong>
                <ol>
                  <li>කැමරාව භාවිතා කිරීමට අවසර ඉල්ලන්නේ නම්, එය ලබා දෙන්න.</li>
                  <li>
                    ඔබට දිස්වන්නේ පසුපස කැමරාවෙන් ලැබෙන රූප රාමු නොවේ නම් (
                    මුහුනත කැමරාව පෙනෙන්නේ නම්), Video Source ලැයිස්තුවෙන් පසුපස
                    කැමරාව තෝරන්න.
                  </li>
                  <li>කැමරාව Data Matrix Code එක වෙත යොමු කරන්න.</li>
                </ol>
              </div>
            </template>
            <br />
            <div class="product-data" v-if="showData">
              Product code: <strong>{{ data.productCode }}</strong
              ><br />
              Batch number: <strong>{{ data.batchNumber }} </strong><br />
              Serial number: <strong>{{ data.serialNumber }} </strong><br />
              Customer: <strong>{{ data.customer }} </strong><br />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar dense v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.msg }}
    </v-snackbar>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        <small>
          Powered by YUGA Invent Private Limited
        </small>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// import UserProfile from '@/components/UserProfile.vue';
import Consts from '@/consts';
import Loading from '@/components/Loading';
import { HTTP } from '../http-common.js';

export default {
  name: 'Home',

  components: { Loading },

  data() {
    return {
      snackbar: {
        timeout: 2000,
        msg: '',
        show: false
      },
      loadingCount: 0,
      colorBlue: Consts.CHEVRON_BLUE,
      colorRed: Consts.CHEVRON_RED,
      showScanner: false,
      showData: false,
      data: {}
    };
  },

  mounted() {},

  created: function() {},

  methods: {
    onDecode: function(result) {
      const identifier = result.replace(' ', '');
      this.loadingCount++;
      HTTP.get(`/itemData/${identifier}`).then(res => {
        this.loadingCount--;
        this.data = res.data;
        this.showScanner = false;
        this.showData = true;
      });
    },

    onLoaded: function() {}
  },

  computed: {}
};
</script>

<style>
.product-data {
  background-color: #07a8ee;
  padding: 10px;
}
</style>
